import React from 'react';
import Logo from 'components/UI/Logo/Logo';
import Footers from 'components/Footer/Footer';
import FooterMenu, { SocialMenu } from './FooterMenu';

const Footer = (props) => {

  const { metamaskConnected, account, setMetamaskConnnected } = props;

  return (
    <Footers
      logo={
        <Logo
          withLink
          linkTo="/"
          src="/images/logo-alt.svg"
          title="Premier Vacations"
        />
      }
      menu={<FooterMenu 
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />}
      social = { <SocialMenu /> }
      copyright={`Copyright @ ${new Date().getFullYear()} Premier Vacations, LLC.`}
    />
  );
};

export default Footer;
