import React from 'react';
import { useNavigate } from "react-router-dom";

import { Menu, Button } from 'antd';

import { AGENT_PROFILE_PAGE } from 'settings/constant';

const AuthMenu = (props) => {
  let navigate = useNavigate();
  const { className, metamaskConnected, account, setMetamaskConnnected } = props;
  
  const handleConnectWallet = async () => {
    if (window.ethereum) {
      await window.ethereum.enable();
      setMetamaskConnnected(true);
      console.log("wallet address is ", account)
      window.location.reload();
    }
  };

  const handleProfilePage = (e) => {
    e.preventDefault();
    navigate(AGENT_PROFILE_PAGE, { replace: true });
  }

  return (
    <Menu className={className}>
      {/* <Menu.Item key="0">
        <NavLink to={LOGIN_PAGE}>Sign in</NavLink>
      </Menu.Item> */}

      {metamaskConnected ? (
        <div className="address-purple">
          {account &&
            <Button type="primary" onClick={handleProfilePage}> {account.substring(0, 6)}...{account.substring(account.length - 4)} </Button>
          }
        </div>
      ) : (        
        <Menu.Item key="1">
          <Button type="primary" onClick={handleConnectWallet}>Connect Wallet</Button>
        </Menu.Item>
      )}

    </Menu>
  );
};

export default AuthMenu;
