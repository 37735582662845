import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AxiosFetch from 'library/hooks/AxiosFetch';
import { notification } from 'antd';

export const AuthContext = React.createContext();

const fakeUserData = {
  id: 1,
  name: 'Jhon Doe',
  avatar:
    'http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png',
  roles: ['USER', 'ADMIN'],
};

const AuthProvider = (props) => {

  const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description: description,
      placement: 'bottomRight'
    });
  };

  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const signIn = async (params) => {
    let url = '/login';
    let res = await AxiosFetch(url, params, "POST");
    console.log(res)
    if(!res.data.success)
    {
      openNotificationWithIcon('error', 'Error!', res.data.message)
      return;
    }
    openNotificationWithIcon('success', 'Login Success!', "You are logged in the admin panel.")    
    setUser(res.data.data);
    setLoggedIn(true);
    navigate('admin', { replace: true });
  };

  const signUp = (params) => {
    console.log(params, 'sign up form Props');
    // setUser(fakeUserData);
    // setLoggedIn(true);
    // navigate('/', { replace: true });
  };

  const logOut = () => {
    setUser(null);
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
