import React, { Fragment } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout as LayoutWrapper } from 'antd';
import useWindowSize from 'library/hooks/useWindowSize';
import LayoutProvider from 'context/LayoutProvider';
import {
  LISTING_POSTS_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  AGENT_PROFILE_PAGE,
  ADMIN_PANEL,
  ADD_HOTEL_PAGE,
  PRICING_PLAN_PAGE,
  SINGLE_POST_PAGE,
  PRIVACY_PAGE,
  CHANGE_PASSWORD_PAGE,
  FORGET_PASSWORD_PAGE,
  ADMIN_MINTED_NFTS,
  ADMIN_OWNERS,
} from 'settings/constant';
import Header from './Header/Header';
import Footer from './Footer/Footer';
const { Content } = LayoutWrapper;

const Layout = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;

  let location = useLocation();
  const { width } = useWindowSize();
  const singlePageUrlFromConst = SINGLE_POST_PAGE.split('/');
  const singlePageUrlFormLocation = location.pathname.split('/');

  return (
    <LayoutProvider>
      {location.pathname === LOGIN_PAGE ||
      location.pathname === CHANGE_PASSWORD_PAGE ||
      location.pathname === FORGET_PASSWORD_PAGE ||
      location.pathname === REGISTRATION_PAGE ? (
        <Content>
          <Outlet />
        </Content>
      ) : (
        <Fragment>
          <Header 
            metamaskConnected={metamaskConnected}
            setMetamaskConnnected={setMetamaskConnnected}
            account={account}
          />
          <Content>
            <Outlet />
          </Content>
          {location.pathname === LISTING_POSTS_PAGE ||
          location.pathname === PRICING_PLAN_PAGE ||
          location.pathname === ADD_HOTEL_PAGE ||
          location.pathname === AGENT_PROFILE_PAGE ||
          location.pathname === CHANGE_PASSWORD_PAGE ||
          location.pathname === FORGET_PASSWORD_PAGE ||
          location.pathname === PRIVACY_PAGE ||
          location.pathname ===
            `${ADMIN_PANEL + ADMIN_MINTED_NFTS}` ||
          location.pathname ===
            `${ADMIN_PANEL + ADMIN_OWNERS}` ||
          location.pathname === ADMIN_PANEL ? (
            <div style={{ height: '33px' }} />
          ) : (
            <Fragment>
              <Footer 
                metamaskConnected={metamaskConnected}
                setMetamaskConnnected={setMetamaskConnnected}
                account={account}
              />
              {singlePageUrlFormLocation[1] === singlePageUrlFromConst[1] && (
                <Fragment>
                  {width < 1200 && <div style={{ height: '74px' }} />}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </LayoutProvider>
  );
}

export default Layout;