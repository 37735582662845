import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const ProfileMenuWrapper = styled.header`
  .ant-menu {
    background: transparent !important;
    border-right: none !important;
  }
`;

export default ProfileMenuWrapper;
