// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const CAR_LISTING_POSTS_PAGE = '/cars';
export const PLANE_LISTING_POSTS_PAGE = '/jets';
export const SINGLE_POST_PAGE = '/collection';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const MINT_PAGE = '/mint';
export const AGENT_PROFILE_FAVORITE = 'favorite-post';
export const AGENT_PROFILE_CONTACT = 'contact';
export const ADMIN_PANEL = '/admin';
export const AGENT_PROFILE_EDIT_PAGE = 'edit';
export const ADMIN_COLLECTIONS = 'collections';
export const ADMIN_MINTED_NFTS = 'minted-nfts';
export const ADMIN_OWNERS = 'owners';
export const AGENT_PROFILE_DELETE = '/delete';
export const ADMIN_NFT_SETTINGS = 'nft-settings'
export const ADMIN_TOKEN_SEND = 'token-send'
export const ADMIN_NFT_AIRDROP = 'nft-airdrop'
export const ADMIN_BOOKING = 'bookings'

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const COMING_SOON_PAGE = '/coming-soon';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';
export const BUY_PREMIER_PAGE = "/buy-premier";
