import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  HOME_PAGE,  
  CAR_LISTING_POSTS_PAGE,  
  PLANE_LISTING_POSTS_PAGE,
  MINT_PAGE,
  COMING_SOON_PAGE,
  BUY_PREMIER_PAGE
} from 'settings/constant';

const MainMenu = ({ className }) => {
  return (
    <Menu className={className}>
      <Menu.Item key="6">
        <div id="calendly_div"></div>
      </Menu.Item>
      <Menu.Item key="0">
        <NavLink to={HOME_PAGE}>Home</NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={COMING_SOON_PAGE}>Real Estate</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={CAR_LISTING_POSTS_PAGE}>Cars</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={PLANE_LISTING_POSTS_PAGE}>Jets</NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={MINT_PAGE}>Mint</NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink to={BUY_PREMIER_PAGE}>Buy Premier</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default MainMenu;
