// import { useState, useReducer, useEffect } from 'react';
import axios from 'axios';

async function AxiosFetch( url, body = {}, method = "GET" ) {
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL, //YOUR_API_URL HERE
    mode:'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  let res = {};
  if(method == "GET")
    res = await authAxios.get(url, body);
  else if(method == "POST")
    res = await authAxios.post(url, body);
  return res;
}

export default AxiosFetch;
